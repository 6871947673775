<template>

  <main class="-mt-24 pb-8">
    <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="sr-only">Page title</h1>
      <!-- Main 3 column grid -->
      <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <!-- Left column -->
        <div class="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title">
            <div class="bg-white overflow-hidden shadow">

              <div>

                <div class="mt-6 flex px-6 justify-end">
                    <span class="relative z-0 inline-flex shadow-sm rounded-md">
                      <button @click="filterByType('fiat')" type="button" :class="{ 'bg-gray-50': filterBy === 'fiat' }" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        Fiat / Stablecoins
                      </button>
                      <button @click="filterByType('all')" type="button" :class="{ 'bg-gray-50': filterBy === 'all' }" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        All
                      </button>
                    </span>
                </div>

              </div>

  <div>


    <div class="mt-8 sm:block">
      <div class="align-middle inline-block min-w-full border-b border-gray-200">
        <table class="min-w-full">
          <thead>
          <tr class="border-t border-gray-200">
            <th @click="sort('coin')" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <span class="lg:pl-2">Currency</span>
            </th>
            <th @click="sort('borrow_size')" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              24H Average Borrowed
            </th>
            <th @click="sort('estimate')" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Estimate APY
            </th>
            <th @click="sort('previous')" class="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Previous APY
            </th>
            <th class="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-100">
          <tr v-for="rate in sortedRates" v-bind:key="rate.coin">
            <td class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
              <div class="flex items-center space-x-3 lg:pl-2">
                <div class="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-pink-600" aria-hidden="true"></div>
                <router-link :to="'/rate/'+rate.coin" class="truncate hover:text-gray-600">
                      <span>{{ rate.coin }} <span class="text-gray-500 font-normal">at FTX</span></span>
                </router-link>
              </div>
            </td>
            <td class="px-6 py-3 text-sm text-gray-500 font-medium">
              {{ (rate.borrow_size).toFixed(0) }} {{ rate.coin }}
            </td>
            <td class="px-6 py-3 text-sm text-gray-500 font-medium">
              {{ (rate.estimate*24*365*100).toFixed(2) }} %
            </td>
            <td class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
              {{ (rate.previous*24*365*100).toFixed(2) }} %
            </td>
            <td class="pr-6"></td>
          </tr>

          <!-- More items... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>


            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import orderBy from 'lodash/orderBy'

//import LineChart from '../line-chart'

export default {
  components: {
  },
  name: 'HelloWorld',
  data() {
    return {
      orderBy: 'estimate',
      orderByDirection: 'desc',
      filterBy: null,
      rates: [],
      currentCoin: null,
      chart: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [150, 230, 224, 218, 135, 147, 260],
          type: 'line'
        }]
      }
    }
  },
  methods: {
    sort(column) {
      this.orderBy = column
      if(this.orderByDirection === 'asc') {
        this.orderByDirection = 'desc'
      } else {
        this.orderByDirection = 'asc'
      }
    },
    filterByType(type) {
      this.filterBy = type;
    }
  },
  computed: {
    sortedRates() {
      return orderBy([...this.filteredRates], [this.orderBy], [this.orderByDirection])
    },
    filteredRates() {
      let filter = []

      if(this.filterBy == 'fiat') {
        filter = ['USD', 'EUR', 'AUD', 'USDT', 'DAI', 'GBP', 'CUSDT', 'CAD']
      } else {
        return this.rates
      }

      return this.rates.filter((a) => filter.includes(a.coin));
    }
  },
  mounted() {
    this.filterByType('fiat')

    fetch('https://lending.tools/api/rates')
            .then(response => response.json())
            .then(data => {
              this.rates = data
            })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
